import {
  RestaurantReservationBookContractResource,
  RestaurantReservationBookContractResource_FeeStatus,
  RestaurantReservationBookContractResource_UserFeeType,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant_reservation_book_contract/restaurant_reservation_book_contract_resource'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { displayToastInfo } from 'components/Shared/Toast'
import dayjs from 'dayjs'
import { onError, useToken } from 'models/Auth'
import {
  getFeeStatusName,
  getUserFeeTypeName,
  restaurantReservationBookContractService,
} from 'models/RestaurantReservationBookContract'
import { parseIntOrKeepOriginal } from 'modules/zod'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { z } from 'zod'

function RestaurantReservationBookContractForm({
  restaurantId,
  restaurantReservationBookContract,
  mutate,
}: {
  restaurantId?: string
  restaurantReservationBookContract?: RestaurantReservationBookContractResource
  mutate?: () => void
}) {
  const token = useToken()
  const navigate = useNavigate()

  const schema = z.object({
    feeStatus: z.number(),
    monthlyFee: z.number(),
    contractPeriod: z.number(),
    lunchCommissionFee: z.number(),
    dinnerCommissionFee: z.number(),
    userFeeType: z.number(),
    userFee: z.number().min(0),
    startDate: z.string(),
    endDate: z.string().optional(),
    memo: z.string().optional(),
  })

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      feeStatus:
        restaurantReservationBookContract?.feeStatus ??
        RestaurantReservationBookContractResource_FeeStatus.COMMISSION,
      monthlyFee: restaurantReservationBookContract?.monthlyFee ?? 5000,
      contractPeriod: restaurantReservationBookContract?.contractPeriod ?? 6,
      lunchCommissionFee:
        restaurantReservationBookContract?.lunchCommissionFee ?? 50,
      dinnerCommissionFee:
        restaurantReservationBookContract?.dinnerCommissionFee ?? 150,
      startDate:
        restaurantReservationBookContract?.startDate !== undefined
          ? dayjs
              .unix(restaurantReservationBookContract?.startDate.seconds)
              .format()
          : dayjs().format(),
      endDate:
        restaurantReservationBookContract?.endDate !== undefined
          ? dayjs
              .unix(restaurantReservationBookContract?.endDate.seconds)
              .format()
          : undefined,
      memo: restaurantReservationBookContract?.memo ?? undefined,
      userFeeType:
        restaurantReservationBookContract?.userFeeType ??
        RestaurantReservationBookContractResource_UserFeeType.DEFAULT,
      userFee: restaurantReservationBookContract?.userFee ?? 0,
    },
  })

  const onCreate = useCallback(
    async (values: z.TypeOf<typeof schema>) => {
      if (restaurantId === undefined) return

      const params = {
        restaurantId: +restaurantId,
        startDate: values.startDate,
        endDate:
          values.endDate !== undefined && values.endDate !== 'NaN'
            ? { value: values.endDate }
            : undefined,
        monthlyFee: values.monthlyFee,
        contractPeriod: values.contractPeriod,
        lunchCommissionFee: values.lunchCommissionFee,
        dinnerCommissionFee: values.dinnerCommissionFee,
        memo: values.memo !== undefined ? { value: values.memo } : undefined,
        feeStatus: values.feeStatus,
        userFeeType: values.userFeeType,
        userFee: values.userFee,
      }

      const { response, error } =
        await restaurantReservationBookContractService.create(token, params)

      if (error != null) {
        onError(error)
        return
      }

      if (response !== undefined) {
        displayToastInfo('更新しました')
        mutate && mutate()
        navigate(`/restaurants/${response.restaurant?.id}`)
      }
    },
    [restaurantId, mutate, navigate, token]
  )

  const onUpdate = useCallback(
    async (values: z.TypeOf<typeof schema>) => {
      if (restaurantReservationBookContract === undefined) return

      const params = {
        id: restaurantReservationBookContract.id,
        startDate: values.startDate,
        endDate:
          values.endDate !== undefined && values.endDate !== 'NaN'
            ? { value: values.endDate }
            : undefined,
        monthlyFee: values.monthlyFee,
        contractPeriod: values.contractPeriod,
        lunchCommissionFee: values.lunchCommissionFee,
        dinnerCommissionFee: values.dinnerCommissionFee,
        memo: values.memo !== undefined ? { value: values.memo } : undefined,
        feeStatus: values.feeStatus,
        userFeeType: values.userFeeType,
        userFee: values.userFee,
      }

      const { error } = await restaurantReservationBookContractService.update(
        token,
        params
      )

      if (error != null) {
        onError(error)
        return
      }

      displayToastInfo('更新しました')
      mutate && mutate()
      navigate(
        `/restaurants/${restaurantReservationBookContract.restaurant?.id}`
      )
    },
    [restaurantReservationBookContract, mutate, navigate, token]
  )

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}
      onSubmit={form.handleSubmit(
        restaurantReservationBookContract !== undefined ? onUpdate : onCreate
      )}
    >
      {restaurantId !== undefined && (
        <TextField
          label="レストランID"
          type="string"
          value={restaurantId}
          disabled
        />
      )}

      <Controller
        control={form.control}
        name="feeStatus"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl
              error={error?.message != null && !error.message}
              required
            >
              <FormLabel>契約タイプ</FormLabel>
              <RadioGroup
                name={field.name}
                onChange={(e) => {
                  const value = Number(e.target.value)
                  if (!isNaN(value)) {
                    field.onChange(value)
                  }
                }}
                value={field.value === undefined ? '' : field.value}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={
                    RestaurantReservationBookContractResource_FeeStatus.COMMISSION
                  }
                  control={<Radio />}
                  label={getFeeStatusName(
                    RestaurantReservationBookContractResource_FeeStatus.COMMISSION
                  )}
                />
                <FormControlLabel
                  value={
                    RestaurantReservationBookContractResource_FeeStatus.MONTHLY
                  }
                  control={<Radio />}
                  label={getFeeStatusName(
                    RestaurantReservationBookContractResource_FeeStatus.MONTHLY
                  )}
                />
                <FormControlLabel
                  value={
                    RestaurantReservationBookContractResource_FeeStatus.USER
                  }
                  control={<Radio />}
                  label={getFeeStatusName(
                    RestaurantReservationBookContractResource_FeeStatus.USER
                  )}
                />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />

      <Controller
        control={form.control}
        name="monthlyFee"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              required
              label="月額費用"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">円</InputAdornment>
                ),
              }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="contractPeriod"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              required
              label="契約期間"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ヶ月</InputAdornment>
                ),
              }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="lunchCommissionFee"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              required
              label="ランチ送客手数料"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              inputProps={{ min: 0 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="dinnerCommissionFee"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              required
              label="ディナー送客手数料"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              inputProps={{ min: 0 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="userFeeType"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl
              error={error?.message != null && !error.message}
              required
            >
              <FormLabel>ユーザー手数料タイプ</FormLabel>
              <RadioGroup
                name={field.name}
                onChange={(e) => {
                  const value = Number(e.target.value)
                  if (!isNaN(value)) {
                    field.onChange(value)
                  }
                }}
                value={field.value === undefined ? '' : field.value}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={
                    RestaurantReservationBookContractResource_UserFeeType.DEFAULT
                  }
                  control={<Radio />}
                  label={getUserFeeTypeName(
                    RestaurantReservationBookContractResource_UserFeeType.DEFAULT
                  )}
                />
                <FormControlLabel
                  value={
                    RestaurantReservationBookContractResource_UserFeeType.CUSTOM
                  }
                  control={<Radio />}
                  label={getUserFeeTypeName(
                    RestaurantReservationBookContractResource_UserFeeType.CUSTOM
                  )}
                />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />

      <Controller
        control={form.control}
        name="userFee"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              required
              label="ユーザー予約手数料"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              inputProps={{ min: 0 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="startDate"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="開始日"
                value={dayjs(field.value)}
                onChange={(newValue) =>
                  field.onChange(
                    newValue != null
                      ? (newValue as unknown as dayjs.Dayjs).format(
                          'YYYY/MM/DD'
                        )
                      : 'NaN'
                  )
                }
                slotProps={{
                  textField: {
                    required: true,
                    helperText: error?.message,
                    error: error?.message != null && !error.message,
                  },
                }}
              />
            </LocalizationProvider>
          )
        }}
      />

      <Controller
        control={form.control}
        name="endDate"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="終了日"
                value={field.value != null ? dayjs(field.value) : null}
                onChange={(newValue) =>
                  field.onChange(
                    newValue != null
                      ? (newValue as unknown as dayjs.Dayjs).format(
                          'YYYY/MM/DD'
                        )
                      : 'NaN'
                  )
                }
                slotProps={{
                  textField: {
                    helperText: error?.message,
                    error: error?.message != null && !error.message,
                  },
                }}
              />
            </LocalizationProvider>
          )
        }}
      />

      <Controller
        control={form.control}
        name="memo"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl
              error={error?.message != null && !error.message}
              sx={{ width: '100%' }}
            >
              <FormLabel sx={{ marginBottom: '8px' }}>メモ</FormLabel>
              <TextareaAutosize
                {...field}
                minRows={8}
                maxRows={12}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '3px',
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />

      <Button variant="contained" color="primary" type="submit">
        {restaurantReservationBookContract !== undefined
          ? '更新する'
          : '作成する'}
      </Button>
    </Box>
  )
}

export default function RestaurantReservationBookContractFormWrap() {
  const { restaurantReservationBookContractId, restaurantId } = useParams<{
    restaurantReservationBookContractId?: string
    restaurantId?: string
  }>()
  const { data: restaurantReservationBookContract, mutate } =
    restaurantReservationBookContractService.useGet({
      id: restaurantReservationBookContractId!,
    })

  if (
    restaurantReservationBookContractId !== undefined &&
    restaurantReservationBookContract === undefined
  ) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Breadcrumb
        routes={
          restaurantReservationBookContractId !== undefined
            ? [
                {
                  path: '/restaurant_reservation_book_contracts',
                  breadcrumbName: '予約台帳契約一覧',
                },
                {
                  path: `/restaurant_reservation_book_contracts/${restaurantReservationBookContractId}`,
                  breadcrumbName: `${restaurantReservationBookContractId}`,
                },
                {
                  path: `/restaurant_reservation_book_contracts/${restaurantReservationBookContractId}/edit`,
                  breadcrumbName: '編集',
                },
              ]
            : [
                {
                  path: '/restaurant_reservation_book_contracts',
                  breadcrumbName: '予約台帳契約一覧',
                },
                {
                  path: `/restaurant_reservation_book_contracts/new`,
                  breadcrumbName: '作成',
                },
              ]
        }
      />
      <PageHeader
        title={
          restaurantReservationBookContract !== undefined ? '編集' : '作成'
        }
        sx={{ marginTop: '32px' }}
      >
        <RestaurantReservationBookContractForm
          restaurantId={restaurantId}
          restaurantReservationBookContract={restaurantReservationBookContract}
          mutate={mutate}
        />
      </PageHeader>
    </>
  )
}
