import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { BroadcastMessageResource } from '@hello-ai/proto/src/gen/auto_reserve/admin/broadcast_message/broadcast_message_resource'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  MessageEditor,
  messageEditorSchema,
} from 'components/BroadcastMessage/MessageEditor'
import BroadcastMessagePreviewModal, {
  convertImageWithinMessageList,
} from 'components/BroadcastMessage/PreviewModal'
import Breadcrumb from 'components/Shared/Breadcrumb'
import ConfirmModal from 'components/Shared/ConfirmModal'
import PageHeader from 'components/Shared/PageHeader'
import { displayToastInfo } from 'components/Shared/Toast'
import dayjs from 'dayjs'
import { onError, useToken } from 'models/Auth'
import { broadcastMessageService } from 'models/BroadcastMessage'
import { usePrefectures } from 'models/Prefecture'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { z } from 'zod'

const MODE = {
  IMMEDIATE: 'immediate',
  BOOK: 'book',
} as const

const schema = z
  .object({
    mode: z.union([z.literal(MODE.IMMEDIATE), z.literal(MODE.BOOK)]),
    deliveryAt: z.instanceof(dayjs as unknown as typeof dayjs.Dayjs).nullable(),
    testDelivery: z.boolean(),
    userIds: z.any().nullable(),
    prefectureIds: z.array(z.string()),
    subject: z.string(),
  })
  .and(messageEditorSchema)

type Schema = z.infer<typeof schema>

function BroadcastMessageForm({
  broadcastMessageId,
  broadcastMessage,
  mutate,
}: {
  broadcastMessageId?: string
  broadcastMessage?: BroadcastMessageResource
  mutate?: () => void
}) {
  const token = useToken()
  const navigate = useNavigate()

  const [isTestDelivery, setIsTestDelivery] = useState(
    broadcastMessage?.testDelivery ?? false
  )

  const form = useForm<Schema>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      userIds: null,
      mode: broadcastMessage?.deliveryAt == null ? MODE.IMMEDIATE : MODE.BOOK,
      deliveryAt: broadcastMessage?.deliveryAt
        ? dayjs.unix(broadcastMessage.deliveryAt.seconds)
        : dayjs(),
      testDelivery: isTestDelivery,
      prefectureIds: broadcastMessage?.prefectureIds ?? [],
      subject: broadcastMessage?.subject ?? '',
      messageContents: broadcastMessage
        ? broadcastMessage.messageContents.map((item) => {
            if (item.image) {
              return {
                type: 'remote_photo',
                value: item.image.url,
              }
            }
            if (item.button) {
              return {
                type: 'button',
                value: {
                  text: item.button.text,
                  url: item.button.url,
                },
              }
            }
            return {
              type: 'text',
              value: item.body,
            }
          })
        : [],
    },
  })
  const { data: prefectures } = usePrefectures()

  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const [pendingData, setPendingData] = useState<Schema | null>(null)

  const prefectureOptions = useMemo(() => {
    if (prefectures == null) return []
    return prefectures.map((prefecture) => {
      return {
        value: prefecture?.id,
        label: prefecture?.path?.split('--').join(', '),
      }
    })
  }, [prefectures])

  const handlePressSubmit = useCallback(async () => {
    const values = pendingData
    if (values == null) return
    const contents = await convertImageWithinMessageList(
      values.messageContents
    ).catch(onError)
    if (contents == null || values === null) return
    const userIds = new Uint8Array(values.userIds)

    const payload = {
      deliveryAt:
        values.mode === MODE.BOOK && values.deliveryAt !== null
          ? values.deliveryAt.format('YYYY-MM-DD HH:mm')
          : '',
      contents,
      testDelivery: isTestDelivery,
      prefectureIds: values.prefectureIds,
      subject: values.subject,
      userIds,
      useCsv: userIds.length > 0,
    }

    if (broadcastMessageId !== undefined) {
      const { error } = await broadcastMessageService.update(token, {
        id: broadcastMessageId!,
        ...payload,
      })
      if (error) {
        onError(error)
      } else {
        displayToastInfo('更新が完了しました。')
        navigate(`/broadcast_messages/${broadcastMessageId}`, {
          replace: true,
        })
        mutate && mutate()
      }
    } else {
      const { response, error } = await broadcastMessageService.create(token, {
        ...payload,
      })
      if (error) {
        console.log('error', error)
        onError(error)
      } else {
        displayToastInfo('メッセージを作成しました')
        navigate(`/broadcast_messages${response ? `/${response.id}` : ''}`, {
          replace: true,
        })
      }
    }
  }, [broadcastMessageId, isTestDelivery, navigate, token, pendingData, mutate])

  const prefectureIds = form.watch('prefectureIds')
  const numberOfCustomers = broadcastMessageService.useNumberOfCustomers({
    testDelivery: isTestDelivery,
    prefectureIds,
  })

  const numberOfCustomersLabel = `${
    isTestDelivery ? 'テストユーザーのみ' : '全ての顧客'
  }（${numberOfCustomers?.data?.numberOfCustomers ?? 0}人）`

  const messageContents = form.watch('messageContents')

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
        paddingTop: 1,
      }}
      onSubmit={form.handleSubmit(setPendingData)}
    >
      <FormControl fullWidth>
        <FormLabel>配信対象: {numberOfCustomersLabel}</FormLabel>
      </FormControl>
      <Controller
        control={form.control}
        name="userIds"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl error={error?.message != null && !error.message}>
              <FormLabel>csv</FormLabel>
              <Button component="label" variant="outlined">
                ファイルを選択
                <input
                  hidden
                  accept="text/csv"
                  multiple
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files?.[0]
                    if (file != null) {
                      const reader = new FileReader()
                      reader.onload = (event) => {
                        field.onChange(event.target?.result)
                      }
                      reader.readAsArrayBuffer(file)
                    }
                  }}
                />
              </Button>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />
      <Controller
        control={form.control}
        name="testDelivery"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl error={error?.message != null && !error.message}>
              <FormLabel>テスト配信</FormLabel>
              <Checkbox
                sx={{
                  width: '10%',
                }}
                checked={isTestDelivery}
                onChange={(e) => {
                  setIsTestDelivery(!isTestDelivery)
                  field.onChange(!isTestDelivery)
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />
      <Controller
        control={form.control}
        name="prefectureIds"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl error={error?.message != null && !error.message}>
              <FormLabel>リクエストしたことある店の都道府県</FormLabel>
              <Select
                {...field}
                style={{ width: '100%' }}
                multiple
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => {
                  if (prefectures == null) return null
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            prefectures.find((i) => `${i?.id}` === value)
                              ?.name_i18n
                          }
                        />
                      ))}
                    </Box>
                  )
                }}
              >
                {prefectureOptions.map((option) => (
                  <MenuItem key={option.value} value={`${option.value}`}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />
      <Controller
        control={form.control}
        name="mode"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl
              error={error?.message != null && !error.message}
              required
            >
              <FormLabel>配信日時</FormLabel>
              <RadioGroup
                name={field.name}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={MODE.IMMEDIATE}
                  control={<Radio />}
                  label="今すぐ"
                />
                <FormControlLabel
                  value={MODE.BOOK}
                  control={<Radio />}
                  label="予約"
                />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />
      <Controller
        control={form.control}
        name="deliveryAt"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                format="YYYY/M/d HH:mm"
                disabled={form.watch('mode') !== MODE.BOOK}
                label="配信日時"
                ampm={false}
                value={dayjs(field.value)}
                onChange={(newValue) => field.onChange(newValue)}
                slotProps={{
                  textField: {
                    helperText: error?.message,
                    error: error?.message != null && !error.message,
                  },
                }}
              />
            </LocalizationProvider>
          )
        }}
      />
      <Controller
        control={form.control}
        name="subject"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl error={error?.message != null && !error.message}>
              <FormLabel>件名</FormLabel>
              <TextField
                value={field.value}
                onChange={(newValue) => field.onChange(newValue)}
                style={{ width: '100%' }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>本文</Typography>
        <Button
          type="button"
          color="primary"
          onClick={() => setIsOpenPreview(true)}
          disabled={messageContents.length === 0}
        >
          プレビュー
        </Button>
      </Box>
      {/* TODO: should be remove any */}
      <MessageEditor form={form as any} />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={messageContents.length === 0}
      >
        {broadcastMessage !== undefined ? '更新する' : '作成する'}
      </Button>
      <BroadcastMessagePreviewModal
        data={{
          deliveryAt:
            dayjs(form.watch('deliveryAt'))?.format('YYY-MM-DD HH:mm') ?? '',
          messageItems: messageContents,
        }}
        isOpen={isOpenPreview}
        onClose={() => {
          setIsOpenPreview(false)
        }}
      />
      <ConfirmModal
        type="warning"
        isVisible={pendingData !== null}
        onConfirm={handlePressSubmit}
        onClose={() => setPendingData(null)}
        title={`${
          !isTestDelivery
            ? 'このメッセージは、全ての顧客に配信されます。'
            : 'テストモード中のためadminのユーザーにのみ配信されます。'
        }`}
      />
    </Box>
  )
}

export default function BroadcastMessageFormWrap() {
  const { broadcastMessageId } = useParams<{ broadcastMessageId: string }>()
  const { data: broadcastMessage, mutate } = broadcastMessageService.useGet({
    id: broadcastMessageId!,
  })

  if (broadcastMessageId !== undefined && broadcastMessage === undefined) {
    return <Loading />
  }

  return (
    <>
      <Breadcrumb
        routes={
          broadcastMessage !== undefined
            ? [
                {
                  path: '/broadcast_messages',
                  breadcrumbName: '配信一覧',
                },
                {
                  path: `/broadcast_messages/${broadcastMessageId}`,
                  breadcrumbName: `${(
                    broadcastMessage.messageContents.find(
                      (i) => i.image == null
                    )?.body ?? 'メッセージなし'
                  ).substring(0, 7)}...`,
                },
                {
                  path: `/broadcast_messages/${broadcastMessageId}/edit`,
                  breadcrumbName: '編集',
                },
              ]
            : [
                {
                  path: '/broadcast_messages',
                  breadcrumbName: '配信一覧',
                },
                {
                  path: `/broadcast_messages/new`,
                  breadcrumbName: '作成',
                },
              ]
        }
      />
      <PageHeader
        title={broadcastMessage !== undefined ? '編集' : '作成'}
        sx={{ marginTop: '32px' }}
      >
        <BroadcastMessageForm
          broadcastMessageId={broadcastMessageId}
          broadcastMessage={broadcastMessage}
          mutate={mutate}
        />
      </PageHeader>
    </>
  )
}
