import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { SalesTable } from 'components/Sales/Table'
import PageHeader from 'components/Shared/PageHeader'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useSearchParams } from 'react-router-dom'

dayjs.extend(isSameOrAfter)

export default function Sales() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [date, setDate] = React.useState(() => {
    const year = searchParams.get('year')
    const month = searchParams.get('month')
    if (year && month) {
      return dayjs(`${year}/${month}`)
    }
    return dayjs()
  })

  const year = dayjs(date).year()
  const month = dayjs(date).month() + 1
  const maxDate = dayjs().endOf('month')

  useEffect(() => {
    if (date.isSame(dayjs(), 'month')) {
      setSearchParams()
      return
    }
    setSearchParams({ year: year.toString(), month: month.toString() })
  }, [setSearchParams, date, year, month])

  return (
    <View>
      <PageHeader
        title="売上レポート"
        extra={[
          <IconButton
            key="back-button"
            onClick={() => setDate(date.subtract(1, 'month'))}
          >
            <ChevronLeft />
          </IconButton>,
          <DatePicker<dayjs.Dayjs>
            key="date-picker"
            views={['month', 'year']}
            label="年月"
            format="YYYY/MM"
            maxDate={maxDate}
            value={date}
            onChange={(newValue) => {
              newValue && setDate(newValue)
            }}
            slotProps={{ textField: {} }}
          />,
          <IconButton
            key="forward-button"
            disabled={dayjs(date).isSameOrAfter(maxDate, 'month')}
            onClick={() => setDate(dayjs(date).add(1, 'month'))}
          >
            <ChevronRight />
          </IconButton>,
        ]}
      >
        <SalesTable year={year} month={month} />
      </PageHeader>
    </View>
  )
}
