import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import {
  getAffiliateTypeName,
  getStatusName,
  useMedium,
  useUpdateMedium,
} from 'models/Medium'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  MediumResource,
  MediumResource_AffiliateType,
  MediumResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_resource'
import { zodResolver } from '@hookform/resolvers/zod'
import { onError } from 'models/Auth'
import { displayToastInfo } from 'components/Shared/Toast'

const feeSchema = z
  .string()
  .trim()
  .min(1)
  .transform(Number)
  .pipe(z.number().positive())
  .or(z.number().positive())

const schema = z.object({
  name: z.string().min(1),
  url: z.string().url(),
  affiliateType: z.nativeEnum(MediumResource_AffiliateType),
  affiliateFeeForDomestic: feeSchema,
  affiliateFeeForOverseas: feeSchema,
  status: z.nativeEnum(MediumResource_Status),
})

type Schema = z.infer<typeof schema>

function AffiliateMediumForm({
  medium,
  onSubmit,
}: {
  medium: MediumResource
  onSubmit: (data: Schema) => Promise<void>
}) {
  const { control, handleSubmit } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: medium.name,
      url: medium.url,
      affiliateType: medium.affiliateType,
      affiliateFeeForDomestic: medium.affiliateFeeForDomestic,
      affiliateFeeForOverseas: medium.affiliateFeeForOverseas,
      status: medium.status,
    },
  })

  return (
    <Stack component="form" gap={2} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="サイト名"
            helperText={error?.message}
            error={error != null}
            {...field}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl error={error != null}>
            <FormLabel>ステータス</FormLabel>
            <RadioGroup
              sx={{ flexDirection: 'row' }}
              {...field}
              value={field.value.toString()}
              onChange={(event) => field.onChange(Number(event.target.value))}
            >
              {[
                MediumResource_Status.UNDER_REVIEW,
                MediumResource_Status.CERTIFIED,
                MediumResource_Status.NOT_CERTIFIED,
              ].map((status) => (
                <FormControlLabel
                  key={status}
                  value={status}
                  control={<Radio />}
                  label={getStatusName(status)}
                />
              ))}
              <FormHelperText>{error?.message}</FormHelperText>
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name="url"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="URL"
            helperText={error?.message}
            error={error != null}
            {...field}
          />
        )}
      />
      <Controller
        name="affiliateType"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl error={error != null}>
            <FormLabel>アフィリエイトタイプ</FormLabel>
            <RadioGroup
              sx={{ flexDirection: 'row' }}
              {...field}
              value={field.value.toString()}
              onChange={(event) => field.onChange(Number(event.target.value))}
            >
              {[
                MediumResource_AffiliateType.EMBEDDED,
                MediumResource_AffiliateType.LINK,
              ].map((type) => (
                <FormControlLabel
                  key={type}
                  value={type}
                  control={<Radio />}
                  label={getAffiliateTypeName(type)}
                />
              ))}
              <FormHelperText>{error?.message}</FormHelperText>
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name="affiliateFeeForDomestic"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="国内向け料率"
            helperText={error?.message}
            error={error != null}
            {...field}
          />
        )}
      />
      <Controller
        name="affiliateFeeForOverseas"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="海外向け料率"
            helperText={error?.message}
            error={error != null}
            {...field}
          />
        )}
      />
      <Button variant="contained" type="submit">
        更新する
      </Button>
    </Stack>
  )
}

export default function AffiliateMediumFormPage() {
  const navigate = useNavigate()
  const { mediumId } = useParams<{ mediumId: string }>()
  const { medium, mutate } = useMedium({ id: mediumId! })
  const { trigger: updateMedium } = useUpdateMedium(mediumId!)

  if (medium == null) {
    return <Loading />
  }

  const handleSubmit = async (data: Schema) => {
    const payload = {
      name: { value: data.name },
      status: { value: data.status },
      url: { value: data.url },
      affiliateFeeForDomestic: { value: data.affiliateFeeForDomestic },
      affiliateFeeForOverseas: { value: data.affiliateFeeForOverseas },
      affiliateType: { value: data.affiliateType },
    }

    try {
      await mutate(updateMedium(payload), { revalidate: false })
    } catch (error) {
      onError(error)
      return
    }

    displayToastInfo('更新しました')
    navigate(`/affiliate/media/${medium.id}`)
  }

  return (
    <>
      <Breadcrumb
        routes={[
          { path: '/affiliate/media', breadcrumbName: 'メディア一覧' },
          {
            path: `/affiliate/media/${medium.id}`,
            breadcrumbName: medium.name,
          },
          {
            path: `/affiliate/media/${medium.id}/edit`,
            breadcrumbName: '編集',
          },
        ]}
      />
      <PageHeader title="編集" sx={{ marginTop: '32px' }}>
        <AffiliateMediumForm medium={medium} onSubmit={handleSubmit} />
      </PageHeader>
    </>
  )
}
