import React, { useState } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Dashboard from 'pages/Dashboard'
import Users from 'pages/Users'
import User from 'pages/User'
import Affiliate from 'pages/Affiliate'
import Reviews from 'pages/Reviews'
import Review from 'pages/Review'
import ReviewForm from 'pages/Review/Form'
import AffiliateMedia from 'pages/Affiliate/Media'
import AffiliateMedium from 'pages/Affiliate/Medium'
import AffiliateMediumForm from 'pages/Affiliate/Medium/Form'
import Growth from 'pages/Growth'
import Owners from 'pages/Owners'
import Owner from 'pages/Owner'
import OwnerInvoices from 'pages/OwnerInvoices'
import OwnerInvoice from 'pages/OwnerInvoice'
import OwnerInvoiceForm from 'pages/OwnerInvoice/Form'
import Restaurants from 'pages/Restaurants'
import Restaurant from 'pages/Restaurant'
import RestaurantOrderContracts from 'pages/RestaurantOrderContracts'
import RestaurantOrderContractForm from 'pages/RestaurantOrderContract/Form'
import RestaurantReservationBookContractForm from 'pages/RestaurantReservationBookContract/Form'
import RestaurantReservationBookContracts from 'pages/RestaurantReservationBookContracts'
import BroadcastMessages from 'pages/BroadcastMessages'
import BroadcastMessage from 'pages/BroadcastMessage'
import BroadcastMessageForm from 'pages/BroadcastMessage/Form'
import Sales from 'pages/Sales'
import MenuIcon from '@mui/icons-material/Menu'

import { signOut } from 'models/Auth'

import {
  AppBar,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import StorefrontIcon from '@mui/icons-material/Storefront'
import MovingIcon from '@mui/icons-material/Moving'
import ForumIcon from '@mui/icons-material/Forum'
import ExploreIcon from '@mui/icons-material/Explore'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import LogoutIcon from '@mui/icons-material/Logout'
import { Box } from '@mui/system'
import { spacing } from 'theme'
import RestaurantOwnershipVerificationRequests from 'pages/RestaurantOwnershipVerificationRequests'
import RestaurantOwnershipVerificationRequest from 'pages/RestaurantOwnershipVerificationRequest'

type Menu = {
  key: string
  text: string
  icon: React.ReactNode
  path: `/${string}`
  nestedItems?: Omit<Menu, 'nestedItems' | 'icon'>[]
}

function NestedListItem({
  icon,
  text,
  nestedItems,
}: Omit<Menu, 'avoid eslint rule'>) {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <ListItemButton
        onClick={() => {
          setOpen((prev) => !prev)
        }}
      >
        <ListItemIcon sx={{ color: 'white', minWidth: 'fit-content', mr: 1 }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List component="div" disablePadding>
          {nestedItems?.map((childItem) => (
            <ListItemButton
              key={childItem.key}
              sx={{ pl: 4 }}
              onClick={(e) => {
                e.preventDefault()
                navigate(childItem.path)
              }}
              component="a"
              href={childItem.path}
            >
              <ListItemText primary={childItem.text} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  )
}

const MENUS: Menu[] = [
  { key: 'home', text: 'ホーム', icon: <HomeIcon />, path: '/' },
  { key: 'users', text: 'ユーザー', icon: <PersonIcon />, path: '/users' },
  {
    key: 'owners',
    text: 'オーナー',
    icon: <AccountBalanceIcon />,
    nestedItems: [
      { key: 'owners_list', text: '一覧', path: '/owners' },
      { key: 'owner_invoices', text: '請求一覧', path: '/owner_invoices' },
      {
        key: 'owner_invoices',
        text: '営業許可証申請一覧',
        path: '/restaurant_ownership_verification_requests',
      },
    ],
    path: '/owners',
  },
  {
    key: 'restaurants',
    text: 'レストラン',
    icon: <StorefrontIcon />,
    nestedItems: [
      { key: 'restaurants_list', text: '一覧', path: '/restaurants' },
      {
        key: 'restaurant_order_contracts',
        text: 'セルフオーダー契約一覧',
        path: '/restaurant_order_contracts',
      },
      {
        key: 'restaurant_reservation_book_contracts',
        text: '予約台帳契約一覧',
        path: '/restaurant_reservation_book_contracts',
      },
    ],
    path: '/restaurants',
  },
  { key: 'growth', text: 'グロース', icon: <MovingIcon />, path: '/growth' },
  { key: 'reviews', text: '口コミ', icon: <ForumIcon />, path: '/reviews' },
  {
    key: 'affiliate',
    text: 'アフィリエイト',
    icon: <ExploreIcon />,
    nestedItems: [
      { key: 'affiliate_top', text: 'トップ', path: '/affiliate' },
      { key: 'affiliate_media', text: 'メディア', path: '/affiliate/media' },
    ],
    path: '/affiliate',
  },
  {
    key: 'broadcast_messages',
    text: '配信',
    icon: <MailOutlineIcon />,
    path: '/broadcast_messages',
  },
  {
    key: 'sales',
    text: '売上',
    icon: <AttachMoneyIcon />,
    path: '/sales',
  },
  { key: 'logout', text: 'ログアウト', icon: <LogoutIcon />, path: '/' },
]

function Menus() {
  const navigate = useNavigate()
  return (
    <List sx={{ color: 'white' }} component="nav">
      {MENUS.map((menu) =>
        menu.nestedItems ? (
          <NestedListItem {...menu} key={menu.key} />
        ) : (
          <ListItemButton
            component="a"
            href={`/${menu.path}`}
            key={menu.key}
            onClick={(e) => {
              e.preventDefault()
              if (menu.key === 'logout') {
                signOut()
              } else if (menu.path != null) {
                navigate(menu.path)
              }
            }}
          >
            <ListItemIcon
              sx={{ color: 'white', minWidth: 'fit-content', mr: 1 }}
            >
              {menu.icon}
            </ListItemIcon>
            <ListItemText primary={menu.text} />
          </ListItemButton>
        )
      )}
    </List>
  )
}

const drawerWidth = 240
const appBarHeight = 64

export default function DashboardNavigation() {
  const location = useLocation()
  const xs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const [drawerVisible, setDrawerVisible] = useState(!xs)

  const onClose = () => {
    setDrawerVisible(false)
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          height: appBarHeight,
          backgroundColor: 'white',
        }}
      >
        <Toolbar>
          <IconButton
            onClick={() => setDrawerVisible((prev) => !prev)}
            color="inherit"
            edge="start"
            sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h3" color="black">
            AR Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          width: { sm: `${drawerWidth}px` },
        }}
      >
        <Drawer
          onClose={onClose}
          variant={!xs ? 'permanent' : 'temporary'}
          open={xs ? drawerVisible : true}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#041527',
            },
          }}
        >
          <Menus />
        </Drawer>
      </Box>
      <Box
        sx={{
          py: { xs: 2, sm: 4 },
          px: 4,
          flex: 1,
          backgroundColor: '#F2F2F2',
          overflow: 'scroll',
          marginTop: appBarHeight / spacing,
        }}
      >
        <Routes location={location}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:userId" element={<User />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/reviews/:reviewId" element={<Review />} />
          <Route path="/reviews/:reviewId/edit" element={<ReviewForm />} />
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="/affiliate/media" element={<AffiliateMedia />} />
          <Route
            path="/affiliate/media/:mediumId"
            element={<AffiliateMedium />}
          />
          <Route
            path="/affiliate/media/:mediumId/edit"
            element={<AffiliateMediumForm />}
          />
          <Route path="/growth" element={<Growth />} />
          <Route path="/broadcast_messages" element={<BroadcastMessages />} />
          <Route
            path="/broadcast_messages/:broadcastMessageId"
            element={<BroadcastMessage />}
          />
          <Route
            path="create_broadcast_message"
            element={<BroadcastMessageForm />}
          />
          <Route
            path="/broadcast_messages/:broadcastMessageId/edit"
            element={<BroadcastMessageForm />}
          />
          <Route
            path="/broadcast_messages/new"
            element={<BroadcastMessageForm />}
          />
          <Route path="/owners" element={<Owners />} />
          <Route path="/owners/:ownerId" element={<Owner />} />
          <Route path="/owner_invoices" element={<OwnerInvoices />} />
          <Route
            path="/owner_invoices/:ownerInvoiceId"
            element={<OwnerInvoice />}
          />
          <Route path="/owner_invoices/new" element={<OwnerInvoiceForm />} />
          <Route
            path="/owner_invoices/:ownerInvoiceId/edit"
            element={<OwnerInvoiceForm />}
          />
          <Route
            path="/restaurant_ownership_verification_requests"
            element={<RestaurantOwnershipVerificationRequests />}
          />
          <Route
            path="/restaurant_ownership_verification_requests/:restaurantOwnershipVerificationRequestId"
            element={<RestaurantOwnershipVerificationRequest />}
          />
          <Route path="/restaurants" element={<Restaurants />} />
          <Route path="/restaurants/:restaurantId" element={<Restaurant />} />
          <Route
            path="/restaurant_order_contracts"
            element={<RestaurantOrderContracts />}
          />
          <Route
            path="/restaurant_order_contracts/:restaurantOrderContractId/edit"
            element={<RestaurantOrderContractForm />}
          />
          <Route
            path="/restaurants/:restaurantId/restaurant_order_contracts/new"
            element={<RestaurantOrderContractForm />}
          />
          <Route
            path="/restaurant_reservation_book_contracts/:restaurantReservationBookContractId/edit"
            element={<RestaurantReservationBookContractForm />}
          />
          <Route
            path="/restaurants/:restaurantId/restaurant_reservation_book_contracts/new"
            element={<RestaurantReservationBookContractForm />}
          />
          <Route
            path="/restaurant_reservation_book_contracts"
            element={<RestaurantReservationBookContracts />}
          />
          <Route path="/sales" element={<Sales />} />
        </Routes>
      </Box>
    </Box>
  )
}
