import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  useMedium,
  getStatusName,
  isCertified,
  mediumClient,
  getAffiliateTypeName,
} from 'models/Medium'
import Breadcrumb from 'components/Shared/Breadcrumb'
import ConfirmModal from 'components/Shared/ConfirmModal'
import PageHeader from 'components/Shared/PageHeader'
import { DescriptionItem, Descriptions } from 'components/Shared/Descriptions'
import DataGrid from 'components/Shared/DataGrid'
import { displayToastInfo, displayToastError } from 'components/Shared/Toast'
import { FilterText } from 'components/Filter/FilterText'
import { Button, Box } from '@mui/material'
import { useToken } from 'models/Auth'
import { callRpc, rpcOptions } from 'modules/rpc'
import { DeleteRequest } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_service'
import { UserResource } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_resource'

type UserFilterState = {
  email: string | undefined
}

function UserList({
  users,
  filters,
  setFilters,
}: {
  users: UserResource[]
  filters: UserFilterState
  setFilters: React.Dispatch<React.SetStateAction<UserFilterState>>
}) {
  const navigate = useNavigate()

  return (
    <DataGrid
      rowKey={(record) => record.id}
      columns={[
        {
          title: '名前',
          key: 'name',
          render: (user) => user.name,
        },
        {
          title: 'メールアドレス',
          key: 'email',
          filter: {
            key: 'email',
            FilterContent: (props) => (
              <FilterText label="メールアドレス" {...props} />
            ),
          },
          render: (user) => user.email,
        },
        {
          title: 'アクション',
          key: 'action',
          render: (user) => (
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate(`/users/${user.id}`)}
            >
              詳細
            </Button>
          ),
        },
      ]}
      data={users}
      filterState={filters}
      onChangeFilterValue={(key, newValue) => {
        setFilters((prev) => ({ ...prev, [key]: newValue }))
      }}
      sx={{ minHeight: 400 }}
    />
  )
}

export default function AffiliateMedium() {
  const navigate = useNavigate()
  const { mediumId } = useParams<{ mediumId: string }>()
  const [userFilters, setUserFilters] = useState<UserFilterState>({
    email: undefined,
  })
  const { medium } = useMedium({
    id: mediumId!,
    userEmail:
      userFilters.email !== undefined
        ? { value: userFilters.email }
        : undefined,
  })

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const token = useToken()

  if (medium === undefined) return null

  const handleDelete = async () => {
    const params: DeleteRequest = { id: medium.id }
    const { error } = await callRpc(
      mediumClient.delete(params, rpcOptions({ token }))
    )

    if (error == null) {
      displayToastInfo('メディアを削除しました')
      navigate('/affiliate/media')
    } else {
      displayToastError('メディアの削除に失敗しました')
    }
    setIsDeleteModalOpen(false)
  }

  return (
    <>
      <Breadcrumb
        routes={[
          {
            path: '/affiliate/media',
            breadcrumbName: 'メディア一覧',
          },
          {
            path: `/affiliate/media/${medium.id}`,
            breadcrumbName: medium.name,
          },
        ]}
      />
      <PageHeader
        title={medium.name}
        suffixTitle={getStatusName(medium.status)}
        sx={{ marginTop: '32px' }}
        extra={[
          <Button
            key="edit"
            variant="outlined"
            component={Link}
            to={`/affiliate/media/${medium.id}/edit`}
          >
            編集
          </Button>,
        ]}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Descriptions>
            <DescriptionItem label="サイト名">{medium.name}</DescriptionItem>
            <DescriptionItem label="ステータス">
              {getStatusName(medium.status)}
            </DescriptionItem>
            <DescriptionItem label="URL">
              <a href={medium.url} target="_blank" rel="noreferrer">
                {medium.url}
              </a>
            </DescriptionItem>
            <DescriptionItem label="アフィリエイトタイプ">
              {getAffiliateTypeName(medium.affiliateType)}
            </DescriptionItem>
            <DescriptionItem label="国内向け料率">
              {medium.affiliateFeeForDomestic}
            </DescriptionItem>
            <DescriptionItem label="海外向け料率">
              {medium.affiliateFeeForOverseas}
            </DescriptionItem>
          </Descriptions>
          {!isCertified(medium.status) && (
            <Button
              variant="contained"
              color="error"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              削除
            </Button>
          )}
        </Box>
      </PageHeader>
      <PageHeader title="ユーザー一覧" sx={{ marginTop: 32 }}>
        <UserList
          users={medium.users}
          filters={userFilters}
          setFilters={setUserFilters}
        />
      </PageHeader>
      <ConfirmModal
        type="warning"
        isVisible={isDeleteModalOpen}
        title="メディアを削除します"
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}
