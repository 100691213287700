import { RestaurantOrderContractResource } from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant_order_contract/restaurant_order_contract_resource'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { displayToastInfo } from 'components/Shared/Toast'
import dayjs from 'dayjs'
import { onError, useToken } from 'models/Auth'
import { restaurantOrderContractService } from 'models/RestaurantOrderContract'
import { parseIntOrKeepOriginal } from 'modules/zod'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { z } from 'zod'

function RestaurantOrderContractForm({
  restaurantId,
  restaurantOrderContract,
  mutate,
}: {
  restaurantId?: string
  restaurantOrderContract: RestaurantOrderContractResource | undefined
  mutate?: () => void
}) {
  const token = useToken()
  const navigate = useNavigate()

  const schema = z.object({
    startDate: z.string(),
    endDate: z.string().optional(),
    monthlyFee: z.number(),
    contractPeriod: z.number(),
    majorCardBrandFeeRate: z.number(),
    minorCardBrandFeeRate: z.number(),
    memo: z.string().optional(),
  })

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      startDate:
        restaurantOrderContract?.startDate !== undefined
          ? dayjs.unix(restaurantOrderContract.startDate.seconds).format()
          : dayjs().format(),
      endDate:
        restaurantOrderContract?.endDate !== undefined
          ? dayjs.unix(restaurantOrderContract.endDate.seconds).format()
          : undefined,
      monthlyFee: restaurantOrderContract?.monthlyFee ?? 5000,
      contractPeriod: restaurantOrderContract?.contractPeriod ?? 6,
      majorCardBrandFeeRate:
        restaurantOrderContract?.majorCardBrandFeeRate != null
          ? Number(restaurantOrderContract.majorCardBrandFeeRate)
          : 0.0324,
      minorCardBrandFeeRate:
        restaurantOrderContract?.minorCardBrandFeeRate != null
          ? Number(restaurantOrderContract?.minorCardBrandFeeRate)
          : 0.0374,
      memo: restaurantOrderContract?.memo ?? undefined,
    },
  })

  const onCreate = useCallback(
    async (values: z.TypeOf<typeof schema>) => {
      if (restaurantId === undefined) return

      const params = {
        restaurantId: +restaurantId,
        startDate: values.startDate,
        endDate:
          values.endDate !== undefined && values.endDate !== 'NaN'
            ? { value: values.endDate }
            : undefined,
        monthlyFee: values.monthlyFee,
        contractPeriod: values.contractPeriod,
        majorCardBrandFeeRate: values.majorCardBrandFeeRate.toString(),
        minorCardBrandFeeRate: values.minorCardBrandFeeRate.toString(),
        memo: values.memo !== undefined ? { value: values.memo } : undefined,
      }

      const { response, error } = await restaurantOrderContractService.create(
        token,
        params
      )

      if (error != null) {
        onError(error)
        return
      }

      if (response !== undefined) {
        displayToastInfo('更新しました')
        mutate && mutate()
        navigate(`/restaurants/${response.restaurant?.id}`)
      }
    },
    [navigate, restaurantId, token, mutate]
  )

  const onUpdate = useCallback(
    async (values: z.TypeOf<typeof schema>) => {
      if (restaurantOrderContract === undefined) return

      const params = {
        id: restaurantOrderContract.id,
        monthlyFee: values.monthlyFee,
        contractPeriod: values.contractPeriod,
        majorCardBrandFeeRate: values.majorCardBrandFeeRate.toString(),
        minorCardBrandFeeRate: values.minorCardBrandFeeRate.toString(),
        startDate: values.startDate,
        endDate:
          values.endDate !== undefined && values.endDate !== 'NaN'
            ? { value: values.endDate }
            : undefined,
        memo: values.memo !== undefined ? { value: values.memo } : undefined,
      }

      const { error } = restaurantOrderContractService.update(token, params)

      if (error != null) {
        onError(error)
        return
      }

      displayToastInfo('更新しました')
      mutate && mutate()
      navigate(`/restaurants/${restaurantOrderContract.restaurant?.id}`)
    },
    [navigate, restaurantOrderContract, token, mutate]
  )

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}
      onSubmit={form.handleSubmit(
        restaurantOrderContract !== undefined ? onUpdate : onCreate
      )}
    >
      {restaurantId !== undefined && (
        <TextField
          label="レストランID"
          type="string"
          value={restaurantId}
          disabled
        />
      )}

      <Controller
        control={form.control}
        name="monthlyFee"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="月額費用"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">円</InputAdornment>
                ),
              }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="contractPeriod"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="契約期間"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ヶ月</InputAdornment>
                ),
              }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="majorCardBrandFeeRate"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="VISA,MASTER,AMEX決済手数料"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              inputProps={{ min: 0, max: 1, step: 0.0001, precision: 4 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="minorCardBrandFeeRate"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="その他決済手数料"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseIntOrKeepOriginal(e.target.value))
              }
              inputProps={{ min: 0, max: 1, step: 0.0001, precision: 4 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="startDate"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="開始日"
                value={dayjs(field.value)}
                onChange={(newValue) =>
                  field.onChange(
                    newValue != null
                      ? (newValue as unknown as dayjs.Dayjs).format(
                          'YYYY/MM/DD'
                        )
                      : 'NaN'
                  )
                }
                slotProps={{
                  textField: {
                    helperText: error?.message,
                    error: error?.message != null && !error.message,
                  },
                }}
              />
            </LocalizationProvider>
          )
        }}
      />

      <Controller
        control={form.control}
        name="endDate"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="終了日"
                value={field.value != null ? dayjs(field.value) : null}
                onChange={(newValue) =>
                  field.onChange(
                    newValue != null
                      ? (newValue as unknown as dayjs.Dayjs).format(
                          'YYYY/MM/DD'
                        )
                      : 'NaN'
                  )
                }
                slotProps={{
                  textField: {
                    helperText: error?.message,
                    error: error?.message != null && !error.message,
                  },
                }}
              />
            </LocalizationProvider>
          )
        }}
      />

      <Controller
        control={form.control}
        name="memo"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl
              error={error?.message != null && !error.message}
              sx={{ width: '100%' }}
            >
              <FormLabel sx={{ marginBottom: '8px' }}>メモ</FormLabel>
              <TextareaAutosize
                {...field}
                minRows={8}
                maxRows={12}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '3px',
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />

      <Button variant="contained" color="primary" type="submit">
        {restaurantOrderContract !== undefined ? '更新する' : '作成する'}
      </Button>
    </Box>
  )
}

export default function RestaurantOrderContractFormWrap() {
  const { restaurantOrderContractId, restaurantId } = useParams<{
    restaurantOrderContractId?: string
    restaurantId?: string
  }>()
  const { data: restaurantOrderContract, mutate } =
    restaurantOrderContractService.useGet({
      id: +restaurantOrderContractId!,
    })

  return (
    <>
      <Breadcrumb
        routes={
          restaurantOrderContract !== undefined
            ? [
                {
                  path: '/restaurant_order_contracts',
                  breadcrumbName: 'オーダー契約一覧',
                },
                {
                  path: `/restaurant_order_contracts/${restaurantOrderContractId}`,
                  breadcrumbName: `${restaurantOrderContractId}`,
                },
                {
                  path: `/restaurant_order_contracts/${restaurantOrderContractId}/edit`,
                  breadcrumbName: '編集',
                },
              ]
            : [
                {
                  path: '/restaurant_order_contracts',
                  breadcrumbName: 'オーダー契約一覧',
                },
                {
                  path: `/restaurant_order_contracts/new`,
                  breadcrumbName: '作成',
                },
              ]
        }
      />
      <PageHeader
        title={restaurantOrderContract !== undefined ? '編集' : '作成'}
        sx={{ marginTop: '32px' }}
      >
        <RestaurantOrderContractForm
          restaurantId={restaurantId}
          restaurantOrderContract={restaurantOrderContract}
          mutate={mutate}
        />
      </PageHeader>
    </>
  )
}
